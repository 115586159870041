.custom-search-input-extended-sport-wrapper {
    .custom-input-extender-by-sport-wrapper {
        margin-top: 4px;
        border-radius:  6px;
        border: 1px solid  #1262FA;
        background:  #FFF;

        .extender-by-sport{
            display: flex;
            padding:  20px 8px;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 1px solid  #D9D9D9; 

            .extender-by-sport-label {
                display: flex;
                padding: 2px 8px;
                justify-content: center; 
                border-radius: 32px;
                border: 1px solid #D9D9D9;
                text-transform: capitalize;
                cursor: pointer;
                color:  #434343;
                text-align: center;
                /* Body (mini)/Body (mini) - Regular (12px) */
                font-family: Open Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */ 

                &.active {
                    background: #1262FA; 
                    color:  #FFF; 
                 }
            }
        }

        .event-entities-no-info-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px;

            i {
                margin-right: 8px;
            }

            span {
                color: #434343;

                /* Body/Body - Regular (14px) */
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
            }
        }

        .event-entities-wrapper {
            max-height: 400px;
            overflow: scroll;

            .entity-wrapper {
                display: flex;
                padding: 4px 16px;
                justify-content: space-between;
                align-items: center;

                &:hover {
                    background:  #E7EFFF;
                }

                .game-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start; 

                    .game-title {
                        color:  #434343;
                        text-align: left;
                        font-family: Open Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */ 
                    }

                    .game-time {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start; 

                        span {
                            margin-right: 8px;
                        }

                        .game-date {
                            color: #999;
                            text-align: center;
                            font-family: Open Sans;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px; /* 145.455% */
                        }

                        .game-hour {
                            color:  #8C8C8C;
                            font-family: Open Sans;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 16px; /* 145.455% */
                        }

                        .game-status {
                            border-radius: 2px;
                            background:  #F5F5F5;
                            padding: 2px 4px;
                            color: #595959;
                            text-align: center;

                            /* Miscellaneous/Micro text - Regular (9px) */
                            font-family: Open Sans;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px; /* 145.455% */
                        }
                    }
                }

                .action-icons {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    img {
                        margin: 0 4px;
                        cursor: pointer;
                    }
                }
            }
        }
        
    }
}   